import React, { useState } from "react"
import Layout from "../../components/Layout/Layout"
import Slider from "react-slick"
import Page1 from "../../components/ArchitecturalSlides/Page1"
import { useMediaQuery } from 'react-responsive'

const Architecture = () => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 720px)'
  })
  const [touch , setTouch] = useState(true)
  var settings = {
    infinite: false,
    arrows: isTabletOrMobileDevice ? false : true,
    touchMove:touch,
    dots: true,
  }
  function handleChange(newValue) {
    setTouch(newValue);
  }
  return (
    <Layout >
        <Slider {...settings}>
          <Page1 onChange={handleChange}/>
        </Slider>
    </Layout>
  )
}
export default Architecture
